import { Button, Icon, Tab, Tabs } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WebFont from "webfontloader";
import { BackgroundComponet } from "../../components/Background";
import { CanvasArea } from "../../components/CanvasArea";
import { ElementsComponet } from "../../components/Elements";
import { fontFamilyPicker } from "../../components/FontFamily";
// HIDE:- import { LayerManagementComponent } from "../../components/LayerManagement";
// import { IdContext } from "../../components/Contexts/IdContext";
import { Logo } from "../../components/Logo";
import { Pagination } from "../../components/Pagination";
import { Templates } from "../../components/Templates";
import { TextComponet } from "../../components/Text";
import { ToolBar } from "../../components/Toolbar";
import { AddToCart } from "../../components/Toolbar/AddToCart";
import { UploadComponet } from "../../components/Upload";
import { ZoomCanvas } from "../../components/Zoom";
import { closeTab } from "../../utils/helper";
import "./home.css";

export function Home() {
  const { sessionId, apiUrl, cartKey, loadCategories, createID, updateID } =
    useParams();
  const [elementStrokColor, setelementStrokColor] = useState(false);
  const [showUploadToolbar, setshowUploadToolbar] = useState(false);
  const [showTextToolbar, setTextToolbar] = useState(false);
  const [activeTab, setActiveTab] = useState("Templates");
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [showToolbar, setShowToolbar] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Initial selected index
  const [disabledObj, setdisabledObj] = useState(false);
  const [templateID, settemplateID] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [canvasObj, setcanvasObj] = useState(false);
  const [LockObj, setLockObj] = useState("unlock");
  const [canvases, setcanvases] = useState([]);
  const [loader, setLoader] = useState(false);
  // const { setId } = useContext(IdContext);

  /* useEffect(() => {
		if (createID) {
			setId(createID);
		}
		if (updateID) {
			setId(updateID);
		}
	}, [updateID, createID]); */

  useEffect(() => {
    // Load webfont.
    WebFont.load({
      google: {
        families: fontFamilyPicker,
      },
    });

    // Set canvas object for active page.
    // NotCleared:- Purpose of this Code.
    if (canvases) {
      const updatedCanvases = [...canvases];
      const activeCanvas = updatedCanvases[currentPage - 1];

      setcanvasObj(activeCanvas);
      // Show & hide tools options base on type.
      if (activeCanvas !== undefined) {
        // Show & hide tools options base on type.
        activeCanvas.on("mouse:down", function () {
          const activeOBJ = activeCanvas.getActiveObject();
          if (activeOBJ) {
            const type = activeOBJ.get("type");
            setdisabledObj(false);
            setShowToolbar(true);
            if (type === "textbox") {
              setTextToolbar(true);
              setelementStrokColor(false);
              setshowUploadToolbar(false);
            } else if (type === "image") {
              setTextToolbar(false);
              setelementStrokColor(false);
              setshowUploadToolbar(true);
            } else {
              setTextToolbar(false);
              setelementStrokColor(true);
              setshowUploadToolbar(false);
            }
          }
        });

        // Unselect cut & safe margin - For future reference.
        /* const activeObject = activeCanvas.getActiveObject();
        if (activeObject && activeObject.type === "group") {
          activeObject.selectable = false;
          activeObject.evented = false;
          activeObject.hoverCursor = "default";
          activeCanvas.discardActiveObject().renderAll();
        } */
      }
    }
  }, [currentPage]);

  // CLEAR: Tab change event.
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    // Additional logic for tab click event
    var tabIDValue = document.getElementById(
      `bp4-tab-panel_sidepanel_${tabId}`
    );
    if (tabIDValue) {
      tabIDValue.setAttribute("aria-hidden", "false");
    }
  };

  const copySelectedObject = () => {
    if (canvasObj) {
      const activeObject = canvasObj.getActiveObject();
      canvasObj.clipboard = activeObject;
      canvasObj.renderAll();
    }
  };

  const pasteCopiedObject = () => {
    if (canvasObj.clipboard) {
      canvasObj.clipboard.clone((cloned) => {
        canvasObj.discardActiveObject();
        cloned.set({
          left: cloned.left + 10,
          top: cloned.top + 10,
          evented: true,
        });
        if (cloned.type === "activeSelection") {
          // active selection needs a reference to the canvas.
          cloned.canvas = canvasObj;
          cloned.forEachObject((obj) => {
            canvasObj.add(obj);
          });
          cloned.setCoords();
        } else {
          canvasObj.add(cloned);
        }
        canvasObj.setActiveObject(cloned);
        canvasObj.requestRenderAll();
      });
      canvasObj.renderAll();
    }
  };

  const cutSelectedObject = () => {
    if (canvasObj) {
      const activeObject = canvasObj.getActiveObject();
      canvasObj.clipboard = activeObject;
      canvasObj.remove(activeObject);
      canvasObj.renderAll();
    }
  };

  const removeSelectedObjects = () => {
    if (canvasObj) {
      const activeObject = canvasObj.getActiveObject();
      canvasObj.remove(activeObject);
      canvasObj.renderAll();
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey) {
      if (e.key === "c") {
        copySelectedObject();
      } else if (e.key === "v") {
        pasteCopiedObject();
      } else if (e.key === "x") {
        cutSelectedObject();
      }
    } else if (e.key === "Delete") {
      removeSelectedObjects();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [canvasObj]);

  return (
    <div className="main-container">
      <div className="top-header">
        <div className="site-logo">
          <Logo />
        </div>

        {/* NotCleared:- Left Side Panel & Tabs */}
        {sessionId && apiUrl && cartKey && loadCategories && (
          <div className="addto-cart">
            <AddToCart
              sessionId={sessionId}
              apiUrl={apiUrl}
              cartKey={cartKey}
              loadCategories={loadCategories}
              canvases={canvases}
              canvasObjects={canvasObjects}
            />
          </div>
        )}
      </div>
      <div className="main-section">
        <div className="tabpanel">
          {/* Left Side Panel & Tabs */}
          <div className="side-panel">
            <Tabs
              id="sidepanel"
              animate={false}
              vertical={true}
              large={false}
              selectedTabId={activeTab}
              onChange={handleTabChange}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                id="Templates"
                title={
                  <>
                    <div className="left-tab">
                      <Icon size="18" icon="control" />
                    </div>
                    <div className="left-tab-text">Templates</div>
                  </>
                }
                panel={
                  <div className="tab-content">
                    <Templates
                      setLoader={setLoader}
                      settemplateID={settemplateID}
                      templateID={templateID}
                    />
                    <Button
                      onClick={closeTab}
                      className="close-tab"
                      icon={<Icon icon="caret-left" />}
                    />
                  </div>
                }
              />
              <Tab
                id="Text"
                title={
                  <>
                    <div className="left-tab">
                      <Icon size="18" icon="new-text-box" />
                    </div>
                    <div className="left-tab-text">Text</div>
                  </>
                }
                panel={
                  <div className="tab-content">
                    <TextComponet
                      setshowUploadToolbar={setshowUploadToolbar}
                      setelementStrokColor={setelementStrokColor}
                      setTextToolbar={setTextToolbar}
                      Canvaseditor={canvasObj}
                      setShowToolbar={setShowToolbar}
                      closeTab={closeTab}
                    />
                    <Button
                      onClick={closeTab}
                      className="close-tab"
                      icon={<Icon icon="caret-left" />}
                    />
                  </div>
                }
              />
              <Tab
                id="Elements"
                title={
                  <>
                    <div className="left-tab">
                      <Icon size="18" icon="comparison" />
                    </div>
                    <div className="left-tab-element">Elements</div>
                  </>
                }
                panel={
                  <div className="tab-content">
                    <ElementsComponet
                      setshowUploadToolbar={setshowUploadToolbar}
                      setelementStrokColor={setelementStrokColor}
                      setTextToolbar={setTextToolbar}
                      Canvaseditor={canvasObj}
                      setShowToolbar={setShowToolbar}
                      closeTab={closeTab}
                    />
                    <Button
                      onClick={closeTab}
                      className="close-tab"
                      icon={<Icon icon="caret-left" />}
                    />
                  </div>
                }
              />
              <Tab
                id="Uploads"
                title={
                  <>
                    <div className="left-tab">
                      <Icon size="18" icon="cloud-upload" />
                    </div>
                    <div className="left-tab-element">Uploads</div>
                  </>
                }
                panel={
                  <div className="tab-content">
                    <UploadComponet
                      setshowUploadToolbar={setshowUploadToolbar}
                      setelementStrokColor={setelementStrokColor}
                      setTextToolbar={setTextToolbar}
                      Canvaseditor={canvasObj}
                      setShowToolbar={setShowToolbar}
                      closeTab={closeTab}
                    />
                    <Button
                      onClick={closeTab}
                      className="close-tab"
                      icon={<Icon icon="caret-left" />}
                    />
                  </div>
                }
              />
              <Tab
                id="Background"
                title={
                  <>
                    <div className="left-tab">
                      <Icon size="18" icon="layout-grid" />
                    </div>
                    <div className="left-tab-element">Background</div>
                  </>
                }
                panel={
                  <div className="tab-content">
                    <BackgroundComponet
                      Canvaseditor={canvasObj}
                      setShowToolbar={setShowToolbar}
                      closeTab={closeTab}
                    />
                    <Button
                      onClick={closeTab}
                      className="close-tab"
                      icon={<Icon icon="caret-left" />}
                    />
                  </div>
                }
              />
              {/* HIDE:- Hide the Layer Management */}
              {/* <Tab
							id='Layer Management'
							title={
								<>
									<div className='left-tab'>
										<Icon size='18' icon='layers' />
									</div>
									<div className='left-tab-element'>Layer Management</div>
								</>
							}
							panel={
								<div className='tab-content'>
									<LayerManagementComponent
										Canvaseditor={canvasObj}
										setShowToolbar={setShowToolbar}
										closeTab={closeTab}
									/>
									<Button
										onClick={closeTab}
										className='close-tab'
										icon={<Icon icon='caret-left' />}
									/>
								</div>
							}
						/> */}
            </Tabs>
          </div>
        </div>

        {/* Right Side Canvas Panel */}
        <div className="canvas-panel">
          {/* Top Tool Bar Component */}
          {showToolbar ? (
            <ToolBar
              showUploadToolbar={showUploadToolbar}
              elementStrokColor={elementStrokColor}
              Canvaseditor={canvasObj}
              setdisabledObj={setdisabledObj}
              disabledObj={disabledObj}
              showTextToolbar={showTextToolbar}
              setLockObj={setLockObj}
              LockObj={LockObj}
              canvases={canvases}
              canvasObjects={canvasObjects}
            />
          ) : null}

          {/* Main Canvas Editor of the Page */}
          <div className="main-area">
            {loader && <div id="cover-spin"></div>}
            <CanvasArea
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCanvasObjects={setCanvasObjects}
              canvasObjects={canvasObjects}
              setcanvases={setcanvases}
              setcanvasObj={setcanvasObj}
              setLoader={setLoader}
              closeTab={closeTab}
              setShowToolbar={setShowToolbar}
              setTextToolbar={setTextToolbar}
              setelementStrokColor={setelementStrokColor}
              setshowUploadToolbar={setshowUploadToolbar}
              templateID={templateID}
              updateID={updateID}
              createID={createID}
              currentPageObject={canvasObj}
              setSelectedIndex={setSelectedIndex}
            />

            <div className="bottom-section">
              <div className="zomm-canvas">
                {/* Zoom Canvas Functionality */}
                <ZoomCanvas Canvaseditor={canvasObj} />
              </div>
              <div className="pagination">
                {/* API:- Pagination would be present After it */}
                <Pagination
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  canvasObjects={canvasObjects}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
