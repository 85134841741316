import { Icon } from "@blueprintjs/core";
import { fabric } from "fabric";
import React, { useEffect, useRef } from "react";

export function UploadComponet({
  Canvaseditor,
  setShowToolbar,
  setelementStrokColor,
  setTextToolbar,
  setshowUploadToolbar,
  closeTab,
}) {
  const uploadedImages = useRef([]);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (Canvaseditor) {
      canvasRef.current = Canvaseditor;
      // console.log(Canvaseditor, "canvasObject");
    }
  }, [Canvaseditor]);

  const uploadImage = (e) => {
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          uploadedImages.current.push({
            src: e.target.result,
            width: img.width,
            height: img.height,
          });
          updateImageList();
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }

    // Close tab event.
    const resizeWidth = window.innerWidth;
    if (resizeWidth <= 770) {
      closeTab();
    }
  };

  const updateImageList = () => {
    const imageList = document.getElementById("imageList");
    if (!imageList) return;
    imageList.innerHTML = "";
    uploadedImages.current.forEach((item, index) => {
      const imgElement = document.createElement("img");
      imgElement.src = item.src;
      imgElement.loading = "lazy";
      imgElement.style.height = "auto";
      imgElement.addEventListener("click", function () {
        bindImageToCanvas(index);
        displayImageDetails(index);
      });
      imageList.appendChild(imgElement);
    });
  };

  const bindImageToCanvas = (index) => {
    const selectedImage = uploadedImages.current[index];
    const canvas = canvasRef.current;
    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();
    const imageWidth = selectedImage.width;
    const imageHeight = selectedImage.height;

    const imgElement = new Image();
    imgElement.onload = () => {
      const fabricImage = new fabric.Image(imgElement, {
        left: (canvasWidth - imageWidth) / 2,
        top: (canvasHeight - imageHeight) / 2,
        scaleX: 0.5,
        scaleY: 0.5,
      });

      const scaleX = canvasWidth / imageWidth;
      const scaleY = canvasHeight / imageHeight;
      const scale = Math.min(scaleX, scaleY);

      fabricImage.set({
        scaleX: scale,
        scaleY: scale,
        left: (canvasWidth - imageWidth * scale) / 2,
        top: (canvasHeight - imageHeight * scale) / 2,
      });

      canvas.add(fabricImage).setActiveObject(fabricImage);

      fabricImage.on("scaling", function () {
        displayImageDetails(index);
      });

      fabricImage.on("selected", function () {
        displayImageDetails(index);
      });

      fabricImage.on("moving", function () {
        displayImageDetails(index);
      });

      fabricImage.on("rotating", function () {
        displayImageDetails(index);
      });

      canvas.centerObject(fabricImage);
      canvas.renderAll();
      setShowToolbar(true);
      setshowUploadToolbar(true);
      setelementStrokColor(false);
      setTextToolbar(false);
    };

    imgElement.src = selectedImage.src;
  };

  const displayImageDetails = (index) => {
    const imageDetails = document.getElementById("imageDetails");
    if (!imageDetails) return; // Check if the element exists

    imageDetails.innerHTML = "";
    const canvas = canvasRef.current;
    const selectedImage = uploadedImages.current[index];
    const activeObject = canvas.getActiveObject();

    // Check if the activeObject is defined and is an instance of fabric.Image
    if (activeObject && activeObject instanceof fabric.Image) {
      const inchWidth = activeObject.getScaledWidth() / canvas.getZoom() / 96;
      const inchHeight = activeObject.getScaledHeight() / canvas.getZoom() / 96;
      const dpiWidth = selectedImage.width / inchWidth;
      const detailsDiv = document.createElement("div");
      const inchLeft = activeObject.left / canvas.getZoom() / 96;
      const inchTop = activeObject.top / canvas.getZoom() / 96;
      detailsDiv.innerHTML = `
        <p>Left: ${inchLeft.toFixed(2)} inches</p>
        <p>Top:  ${inchTop.toFixed(2)} inches</p>
        <p>Width: ${inchWidth.toFixed(2)} inches</p>
        <p>Height: ${inchHeight.toFixed(2)} inches</p>
        <p>Resolution : ${dpiWidth.toFixed(2)} px</p>
      `;
      imageDetails.appendChild(detailsDiv);
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ height: "45px", paddingTop: "5px" }}>
        Do you want to upload your own images?
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="input-file"
          className="bp4-button"
          style={{ width: "100%" }}
        >
          <Icon size="18" icon="upload" />
          <span className="bp4-button-text">Upload Image</span>
        </label>
        <input
          onChange={uploadImage}
          type="file"
          id="input-file"
          accept="image/*"
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div id="imageDetails"></div>
      <div id="imageList"></div>
    </div>
  );
}
