import React, { useEffect } from "react";
import { DeleteElement } from "./DeleteElement";
import { Duplicate } from "./Duplicate";
import { LockElement } from "./LockElement";
import { FontFamily } from "./Text/FontFamily";
import { FontSize } from "./Text/FontSize";
import { FontStyle } from "./Text/FontStyle";
import { Position } from "./Text/Position";
import { TextColor } from "./Text/TextColor";
import { Transparency } from "./Text/Transparency";
import { SaveContinue } from "./SaveContinue";
import { useParams } from "react-router-dom";
import { ElementStrokColor } from "./ElementStrokColor";
import { FlipPosition } from "./FlipPosition";
import { Layer } from "./Text/Layer";
import { Crop } from "./Crop";

export function ToolBar(editor) {
	const { createID, updateID } = useParams();
	const {
    Canvaseditor,
    disabledObj,
    setdisabledObj,
    showTextToolbar,
    elementStrokColor,
    showUploadToolbar,
    setLockObj,
    LockObj,
    canvases,
    canvasObjects,
  } = editor;

	useEffect(() => {
		if (Canvaseditor.contextContainer) {
      // On object modified.
      Canvaseditor.on("object:modified", function (event) {
        if (event.target && typeof event.target.text !== "undefined") {
          event.target.fontSize *= event.target.scaleX;
          event.target.fontSize = event.target.fontSize.toFixed(0);
          event.target.scaleX = 1;
          event.target.scaleY = 1;
          event.target._clearCache();
          if (document.getElementById("text-font-size")) {
            document.getElementById("text-font-size").value =
              event.target.fontSize;
          }
          let activeTextObject = Canvaseditor.getActiveObject();
          if (activeTextObject) {
            activeTextObject.set("fontSize", event.target.fontSize);
            Canvaseditor.renderAll();
          }
        }
      });

      // On object select.
      Canvaseditor.on("selection:created", function () {
        setdisabledObj(false);
      });

      // On object un select.
      Canvaseditor.on("selection:cleared", function () {
        setdisabledObj(true);
      });

      // Unselect cut & safe margin - For future reference.
      /* var activeObject = Canvaseditor.getActiveObject();
			if (activeObject && activeObject.type === "group") {
				activeObject.selectable = false;
				activeObject.evented = false;
				activeObject.hoverCursor = "default";
				Canvaseditor.discardActiveObject().renderAll();
			} */
    }
	}, []);

	return (
    <nav className="bp4-navbar">
      <div className="bp4-navbar-group bp4-align-left">
        {!disabledObj && (
          <>
            {showUploadToolbar && <FlipPosition Canvaseditor={Canvaseditor} />}
            {elementStrokColor && (
              <ElementStrokColor Canvaseditor={Canvaseditor} />
            )}
            {showTextToolbar && (
              <>
                <TextColor Canvaseditor={Canvaseditor} />
                <FontFamily Canvaseditor={Canvaseditor} />
                <FontSize Canvaseditor={Canvaseditor} />
                <FontStyle Canvaseditor={Canvaseditor} />
              </>
            )}
          </>
        )}

        <Position Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
        <Layer Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
        <Transparency Canvaseditor={Canvaseditor} disabledObj={disabledObj} />

        <LockElement
          Canvaseditor={Canvaseditor}
          setdisabledObj={setdisabledObj}
          setLockObj={setLockObj}
          LockObj={LockObj}
        />
        <Duplicate Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
        {showUploadToolbar && (
          <Crop Canvaseditor={Canvaseditor} disabledObj={disabledObj} />
        )}
        <DeleteElement
          Canvaseditor={Canvaseditor}
          setdisabledObj={setdisabledObj}
          disabledObj={disabledObj}
        />
      </div>
      {(createID || updateID) && (
        <SaveContinue
          updateID={updateID}
          createID={createID}
          canvases={canvases}
          canvasObjects={canvasObjects}
        />
      )}
    </nav>
  );
}
