import React, { useState, useRef, useEffect } from "react";
import { Cropper } from "react-advanced-cropper";
import { Icon, Button, Dialog, Classes } from "@blueprintjs/core";
import "react-advanced-cropper/dist/style.css";

export function Crop({
  Canvaseditor,
  disabledObj
}) {
  const [isOpen, setIsOpen] = useState(false);
  const cropperRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [dialogOpened, setDialogOpened] = useState(false);

  useEffect(() => {
    if (Canvaseditor) {
      canvasRef.current = Canvaseditor;
    }
  }, [Canvaseditor]);

  useEffect(() => {
    if (isOpen) {
      // Set a slight delay to ensure the dialog is fully rendered.
      setTimeout(() => {
        setDialogOpened(true);
      }, 200);
    } else {
      setDialogOpened(false);
    }
  }, [isOpen]);

  const handleOpen = async () => {
    setIsOpen(true);
    const canvas = canvasRef.current;
    const selectedImage = canvas.getActiveObject();
    if (selectedImage && selectedImage.type === "image") {
      const { src } = selectedImage._element;
      /* await setImage(
        "https://images.pexels.com/photos/6524107/pexels-photo-6524107.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      ); */
      await setImage(src);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setImage(null);
    setIsOpen(false);
    setDialogOpened(false);
  };

  const handleCrop = () => {
    const cropper = cropperRef.current;
    if (!cropper) return;
    const croppedCanvas = cropper.getCanvas();
    bindImageToCanvas(croppedCanvas);
    setImage(null);
    setIsOpen(false);
    setDialogOpened(false);
  };

  const bindImageToCanvas = (croppedData) => {
    const selectedImage = croppedData.toDataURL();
    const canvas = canvasRef.current;
    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();
    const imageWidth = croppedData.width;
    const imageHeight = croppedData.height;

    const imgElement = new Image();
    imgElement.onload = () => {
      const fabricImage = new fabric.Image(imgElement, {
        left: (canvasWidth - imageWidth) / 2,
        top: (canvasHeight - imageHeight) / 2,
        scaleX: 0.5,
        scaleY: 0.5,
      });

      const scaleX = canvasWidth / imageWidth;
      const scaleY = canvasHeight / imageHeight;
      const scale = Math.min(scaleX, scaleY);

      fabricImage.set({
        scaleX: scale,
        scaleY: scale,
        left: (canvasWidth - imageWidth * scale) / 2,
        top: (canvasHeight - imageHeight * scale) / 2,
      });

      canvas.add(fabricImage).setActiveObject(fabricImage);

      fabricImage.on("scaling", function () {
        displayImageDetails(croppedData);
      });

      fabricImage.on("selected", function () {
        displayImageDetails(croppedData);
      });

      fabricImage.on("moving", function () {
        displayImageDetails(croppedData);
      });

      fabricImage.on("rotating", function () {
        displayImageDetails(croppedData);
      });

      canvas.centerObject(fabricImage);
      canvas.renderAll();
    };

    imgElement.src = selectedImage;
  };

  /* const defaultSize = ({ imageSize, visibleArea }) => {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    };
  }; */

  const displayImageDetails = (cropDetails) => {
    const imageDetails = document.getElementById("imageDetails");
    if (!imageDetails) return; // Check if the element exists

    imageDetails.innerHTML = "";
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    // Check if the activeObject is defined and is an instance of fabric.Image
    if (activeObject && activeObject instanceof fabric.Image) {
      const inchWidth = activeObject.getScaledWidth() / canvas.getZoom() / 96;
      const inchHeight = activeObject.getScaledHeight() / canvas.getZoom() / 96;
      const dpiWidth = cropDetails.width / inchWidth;
      const detailsDiv = document.createElement("div");
      const inchLeft = activeObject.left / canvas.getZoom() / 96;
      const inchTop = activeObject.top / canvas.getZoom() / 96;
      detailsDiv.innerHTML = `
        <p>Left: ${inchLeft.toFixed(2)} inches</p>
        <p>Top:  ${inchTop.toFixed(2)} inches</p>
        <p>Width: ${inchWidth.toFixed(2)} inches</p>
        <p>Height: ${inchHeight.toFixed(2)} inches</p>
        <p>Resolution : ${dpiWidth.toFixed(2)} px</p>
      `;
      imageDetails.appendChild(detailsDiv);
    }
  };

  return (
    <>
      <div className="crop" style={{ marginRight: "5px" }}>
        <Button
          icon={<Icon icon="clip" />}
          text="Crop"
          onClick={handleOpen}
          disabled={disabledObj}
        />
      </div>
      <Dialog
        onClose={handleClose}
        title={
          <Button
            icon={<Icon icon="clip" />}
            text="Crop and append to canvas"
            onClick={handleCrop}
          />
        }
        isOpen={isOpen}
        className="crop-dialog"
      >
        <div className={Classes.DIALOG_BODY}>
          {dialogOpened && (
            <Cropper
              src={image}
              className={"cropper"}
              ref={cropperRef}
              // defaultSize={defaultSize}
            />
          )}
        </div>
      </Dialog>
    </>
  );
}
